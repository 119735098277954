import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import vue3GoogleLogin from "vue3-google-login";
const app = createApp(App);

app
  .use(router)
  .use(store)
  .use(ToastPlugin, {
    position: "top-right",
  })
  .use(VueSweetalert2)
  .use(vue3GoogleLogin, {
    clientId: "999325409412-mpunviq552k09l2u0dj9fc0p4ggars4g.apps.googleusercontent.com",
  });

app.config.globalProperties.$moment = moment;

app.mount("#app");
