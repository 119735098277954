<template>
    <RouterView />
</template>

<script>
export default {
  name: 'App',
}
</script>
<style>
.form-control,
.form-check-input{
  border-color: rgb(161 161 161 / 47%);
}

.form-control:focus {
    border: 1px solid #669efa;
    box-shadow: 0 0 3px 3px rgba(0, 94, 246, 0.1);
}

.swal2-actions{
  flex-direction: row-reverse;
}

.swal2-styled.swal2-confirm{
  background: rgb(140, 212, 245) !important;
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel{
  background: #C1C1C1 !important;
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm:hover{
  background: rgb(61 170 221) !important;
}

.swal2-styled.swal2-cancel:hover{
  background:#a39f9f !important
}
.cursor-pointer{
  cursor: pointer;
}

.font-light{
  font-weight: 400;
}
</style>
