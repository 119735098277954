import axios from "axios";

const baseDomain = process.env.VUE_APP_API_URL;

const baseURL = `${baseDomain}`;
const axiosInstance = axios.create({
  baseURL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const data = localStorage.getItem("data");
    if (data) {
      const token = JSON.parse(data).token;
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
