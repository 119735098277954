<template>
  <div
    class="has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset"
  >
    <SidebarComponent />

    <main id="content" role="main" class="main pt-0">
      <RouterView />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SidebarComponent from "../Layout/sidebar.vue";
export default {
  name: "LayoutComponent",
  components: { SidebarComponent },
  mounted() {
    if (!this.booking && !localStorage.getItem("data")) {
      this.$router.push({ path: "/" });
      return;
    }

    if (localStorage.getItem("data")) {
      this.$store.commit("setAuthType", "password");
    }

    if (this.$route.name == "MyBooking" && !this.booking) {
        this.$router.push({ path: "/dashboard/bookings" });
      }
  },
  computed: {
    ...mapState({
      booking: (state) => state.booking,
    }),
  },
};
</script>

<style></style>
