<template>
  <div class="w-100 mt-4" style="max-width: 25rem">
    <!-- Form -->
    <form
      action="#"
      method="POST"
      class="js-validate needs-validation"
      @submit="formHandler"
    >
      <div class="text-center">
        <div class="mb-2 border-bottom pb-1">
          <img
            class="logo"
            src="/assets/image/logo.png"
            alt="Image Description"
          />
          <p class="mb-0">Edit and manage your shuttle booking</p>
        </div>
      </div>

      <p class="heading">Login as {{ tab == "password" ? "user" : "guest" }}</p>

      <!-- Form -->
      <div class="mb-4" v-if="tab == 'bookingId'">
        <label class="form-label" for="bookingId">Booking ID</label>
        <input
          type="text"
          class="form-control form-control-lg"
          name="bookingId"
          id="bookingId"
          tabindex="1"
          placeholder="AS123456"
          v-model="formValues.booking_id"
          required
        />
        <span class="invalid-feedback">Please enter a valid booking id.</span>
      </div>
      <div class="mb-4">
        <label class="form-label" for="email">Email</label>
        <input
          type="email"
          class="form-control form-control-lg"
          name="email"
          v-model="formValues.email"
          id="email"
          tabindex="1"
          placeholder="email@gmail.com"
          required
        />
        <span class="invalid-feedback">Please enter a valid booking Id.</span>
      </div>
      <div class="mb-4" v-if="tab == 'password'">
        <label class="form-label w-100" for="password" tabindex="0">
          <span class="d-flex justify-content-between align-items-center">
            <span>Password</span>
            <router-link class="mb-0" to="/forgot-password"
              >Forgot Password?
            </router-link>
          </span>
        </label>
        <input
          type="password"
          class="form-control form-control-lg"
          name="password"
          v-model="formValues.password"
          id="password"
          tabindex="1"
          placeholder="*********"
          required
        />
      </div>
      <!-- End Form -->

      <div class="d-grid border-bottom pb-4">
        <CustomButton
          buttonType="submit"
          classStyle="btn-primary btn-lg"
          btnText="Login"
          :loading="loading"
        />
      </div>

      <p class="my-2 text-center">Or</p>
      <GoogleLogin :callback="callback" prompt>
        <div class="social-btn rounded">
          <img src="/assets/image/google.png" />
          <div>Sign in with Google</div>
        </div>
      </GoogleLogin>

      <div
        class="social-btn rounded"
        v-if="tab == 'bookingId'"
        @click="toggleTab('password')"
      >
        <img src="/assets/image/email.svg" />
        <div>Sign in with Email</div>
      </div>
      <div class="social-btn rounded" v-else @click="toggleTab('bookingId')">
        <img src="/assets/image/appointment.png" />
        <div>Sign in as guest</div>
      </div>

      <p class="text-end mt-2">
        Do you have multiple reservations?
        <router-link to="/register">Create account</router-link>
      </p>
    </form>
    <!-- End Form -->
  </div>
</template>
<script>
import CustomButton from "../components/CustomButton/index.vue";
import axios from "axios";
import { decodeCredential } from "vue3-google-login";

export default {
  name: "AuthView",
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
      tab: "bookingId",
      formValues: {
        email: null,
        booking_id: null,
        password: null,
      },
    };
  },
  methods: {
    toggleTab(tab) {
      this.tab = tab;
      window.scrollTo(0, 0);
    },
    formHandler(e) {
      e.preventDefault();
      this.loading = true;
      let url = "get-user-booking";
      if (this.tab == "password") {
        url = "website/login";
      }
      axios
        .post(`${process.env.VUE_APP_API_URL}${url}`, this.formValues)
        .then((response) => {
          if (response.data.status == 200) {
            if (this.tab == "bookingId") {
              this.$store.commit("setBooking", response.data.data);
              this.$router.push({ path: "/dashboard/my-booking" });
              this.$store.commit("setAuthType", "bookingId");
            } else {
              localStorage.setItem("data", JSON.stringify(response.data.data));
              this.$store.commit("setAuthType", "password");
              this.$router.push({ path: "/dashboard/bookings" });
            }
          } else {
            this.$toast.open({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    callback(response) {
      // decodeCredential will retrive the JWT payload from the credential
      const userData = decodeCredential(response.credential);
      console.log("Handle the userData", userData);
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}website/google-login`, {
          google_id: userData.sub,
          email: userData.email,
          name: userData.name,
        })
        .then((res) => {
          if (res.data.status == 200) {
            localStorage.setItem("data", JSON.stringify(res.data.data));
            this.$store.commit("setAuthType", "password");
            this.$router.push({ path: "/dashboard/bookings" });
          } else {
            this.$toast.open({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
