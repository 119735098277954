import { createStore } from "vuex";

// Create a new store instance.
export const store = createStore({
  state() {
    return {
        booking: null,
        auth_type: 'bookingId'
    };
  },
  mutations: {
    setBooking(state, booking){
        state.booking = booking;
    },
    setAuthType(state, auth_type){
      state.auth_type = auth_type;
    },
  },
});
