<template>
  <button :type="buttonType" :disabled="disabled || loading" :class="`btn ${classStyle}`" @click="$emit('clickHandler')">
    {{ loading ? 'Please wait...' : btnText }}
  </button>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    buttonType: {
      type: String,
      default: "button",
    },
    classStyle: {
      type: String,
      default: "btn-primary",
    },
    btnText: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
