<template>
  <div class="w-100 mt-3" style="max-width: 25rem">
    <!-- Form -->
    <form class="js-validate needs-validation" @submit="formHandler">
      <div class="text-center">
        <div class="mb-2 border-bottom pb-1">
          <img
            class="logo"
            src="/assets/image/logo.png"
            alt="Image Description"
          />
          <p class="mb-0">Enter the email address you used when you joined and we'll send you
            instructions to reset your password.</p>
        </div>
      </div>
      <p class="heading">Forgot password?</p>

      <!-- Form -->
      <div class="mb-4">
        <label class="form-label" for="resetPasswordSrEmail" tabindex="0"
          >Your email</label
        >

        <input
          type="email"
          class="form-control form-control-lg"
          name="email"
          v-model="formValues.email"
          id="resetPasswordSrEmail"
          tabindex="1"
          placeholder="Enter your email address"
          aria-label="Enter your email address"
          required
        />
      </div>
      <!-- End Form -->

      <div class="d-grid gap-2">
        <CustomButton
          buttonType="submit"
          classStyle="btn-primary btn-lg"
          btnText="Submit"
          :loading="loading"
        />

        <div class="text-center">
          <router-link class="btn btn-link font-light" to="/">
            <i class="bi-chevron-left"></i> Back to Sign in
          </router-link>
        </div>
      </div>
    </form>
    <!-- End Form -->
  </div>
</template>
<script>
import CustomButton from "../components/CustomButton/index.vue";
import axios from "axios";

export default {
  name: "AuthView",
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
      formValues: {
        email: null,
      },
    };
  },
  methods: {
    formHandler(e) {
      e.preventDefault();
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}website/forgot-password`, this.formValues)
        .then((response) => {
          if (response.data.status == 200) {
            this.$router.push({ path: "/verify-forgot-password-otp" , query: { email: this.formValues.email }});
            this.$toast.open({
              message: "OTP sent to your email address.",
              type: "success",
            });
          } else {
            this.$toast.open({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
