import { createWebHistory, createRouter } from "vue-router";

import LayoutComponent from "../components/Layout/index.vue";
import AuthLayout from "../components/AuthLayout/index.vue";
import LoginPage from "../pages/LoginView.vue";
import RegisterPage from "../pages/RegisterView.vue";
import ForgotPasswordPage from "../pages/ForgotPasswordView.vue";
import VerifyForgotPasswordPage from "../pages/VerifyForgotPasswordView.vue";
import BookingsPage from "../pages/BookingsView.vue";
import MyBookingPage from "../pages/MyBookingView.vue";
import CustomerFeedbackPage from "../pages/CustomerFeedbackView.vue";

const routes = [
  { 
    path: "/", 
    component: AuthLayout,
    name: 'auth' ,
    children: [
      {
        path: '',
        component: LoginPage,
        name: 'login'
      },
      {
        path: 'register',
        component: RegisterPage,
        name: 'register'
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPage,
        name: 'forgotPassword'
      },
      {
        path: 'verify-forgot-password-otp',
        component: VerifyForgotPasswordPage,
        name: 'verifyForgotPassword',
        props: true
      },
    ]
  },
  { 
    path: "/dashboard", 
    component: LayoutComponent,
    name: 'main',
    children: [
      {
        path: 'bookings',
        component: BookingsPage,
        name: 'Booking'
      },
      {
        path: 'my-booking',
        component: MyBookingPage,
        name: 'MyBooking'
      },
      {
        path: 'customer-feedback',
        component: CustomerFeedbackPage,
        name: 'CustomerFeedback'
      },
    ]
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
