<template>
  <aside
    class="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl"
  >
    <div class="navbar-vertical-container">
      <div class="navbar-vertical-footer-offset">
        <!-- Logo -->
        <a class="navbar-brand" href="#" aria-label="Front">
          <img class="shuttle-logo" src="/assets/image/shuttle.png" alt="Logo" />
          <span class="mr-4 nav-link">My Shuttle Trip</span>
        </a>
        <!-- End Logo -->
        <!-- Navbar Vertical Toggle -->
        <button
          type="button"
          class="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
        >
          <i
            class="bi-arrow-bar-left navbar-toggler-short-align"
            data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Collapse"
          ></i>
          <i
            class="bi-arrow-bar-right navbar-toggler-full-align"
            data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Expand"
          ></i>
        </button>

        <!-- End Navbar Vertical Toggle -->

        <!-- Content -->
        <div class="navbar-vertical-content">
          <div
            id="navbarVerticalMenu"
            class="nav nav-pills nav-vertical card-navbar-nav"
          >
            <!-- Collapse -->
            <div class="nav-item">
              <router-link class="nav-link" exact-active-class="active" :to="{name : auth_type == 'bookingId' ? 'MyBooking' : 'Booking'}" data-placement="left">
                <i class="bi-ticket-detailed nav-icon"></i>
                <span class="nav-link-title">My booking</span>
              </router-link>
            </div>

            <div class="nav-item">
              <router-link class="nav-link" exact-active-class="active" :to="{name : 'CustomerFeedback'}" data-placement="left">
                <i class="bi-headset nav-icon"></i>
                <span class="nav-link-title">Customer service</span>
              </router-link>
            </div>

            <div class="nav-item">
              <a class="nav-link" href="#" data-placement="left" @click="goBack()">
                <i class="bi-box-arrow-right nav-icon"></i>
                <span class="nav-link-title">Logout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "SidebarComponent",
  methods: {
    goBack() {
      this.$store.commit("setBooking", null);
      localStorage.removeItem('data');
      this.$router.push({ path: "/" });
    },
  },
  computed: {
    ...mapState({
      auth_type: (state) => state.auth_type,
    }),
  },
};
</script>

<style>
.navbar{
    background: #f7f7f7;
}

.shuttle-logo{
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin-right: 5px;
}
</style>
