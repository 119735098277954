<template>
  <div class="mt-3 mx-4 border-bottom" v-if="id">
    <h2>{{ morning_message }} 😊,</h2>
    <p>
      Currently, you can only edit the fields that are not in gray background.
      <br />
      Cancellations are permitted up to 12 hours before your arrival flight time
      or departure pickup time.
      <br />
      Changes to your service are allowed up to 72 hours prior to your scheduled
      service.
    </p>
  </div>
  <form
    action="#"
    method="post"
    class="j-pro entry-form"
    id="j-pro"
    enctype="multipart/form-data"
    @submit="submitHandler"
    v-if="id"
  >
    <div class="row justify-content-lg-center m-2">
      <div class="col-lg-12">
        <div>
          <div class="card card-lg active">
            <div class="card-header">
              <h2>Booking Information</h2>
            </div>
            <div class="card-body py-1">
              <div class="row mb-2">
                <div class="col-md-3">
                  <label for="bookingIdLabel" class="col-form-label form-label"
                    >Booking ID
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="booking_id"
                      id="bookingIdLabel"
                      placeholder="Enter Booking ID"
                      aria-label="Clarice"
                      v-model="formValues.booking_id"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label for="nameLabel" class="col-form-label form-label"
                    >Name
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-model="formValues.name"
                      id="nameLabel"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label for="emailLabel" class="col-form-label form-label"
                    >Email
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      id="emailLabel"
                      placeholder="Enter email"
                      aria-label="Clarice"
                      v-model="formValues.email"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label for="phoneLabel" class="col-form-label form-label"
                    >Phone
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="phone"
                      id="phoneLabel"
                      placeholder="Enter Phone"
                      v-model="formValues.phone"
                      aria-label="Clarice"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
              </div>
              <!-- End Form -->
            </div>
            <!-- End Body -->
            <div class="card-header" v-if="arrival">
              <h2>Arrival</h2>
            </div>
            <div class="card-body py-1" v-if="arrival">
              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="fromLabel" class="col-form-label form-label"
                    >From
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control transfer-airport"
                      name="first_transfer_from"
                      v-model="formValues.first_transfer_from"
                      id="fromLabel"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-6">
                  <label for="toLabel" class="col-form-label form-label"
                    >To
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="first_transfer_to"
                      v-model="formValues.first_transfer_to"
                      id="toLabel"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="firstTransferDateLabel"
                    class="col-form-label form-label"
                    >Date
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <flat-pickr
                      v-model="formValues.first_transfer_date"
                      class="js-flatpickr form-control flatpickr-custom"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label for="airlineLabel" class="col-form-label form-label"
                    >Airline
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="first_transfer_airline"
                      id="airlineLabel"
                      aria-label="Clarice"
                      v-model="formValues.first_transfer_airline"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="firstTransferFlightLabel"
                    class="col-form-label form-label"
                    >Flight #
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="first_transfer_flight_no"
                      id="firstTransferFlightLabel"
                      aria-label="Clarice"
                      v-model="formValues.first_transfer_flight_no"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="firstTransferFlightTimeLabel"
                    class="col-form-label form-label"
                    >Flight time
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="time"
                      class="form-control"
                      name="first_transfer_flight_time"
                      id="firstTransferFlightTimeLabel"
                      aria-label="Clarice"
                      v-model="formValues.first_transfer_flight_time"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
              </div>
              <!-- End Form -->
            </div>
            <div class="card-header" v-if="departure">
              <h2>Departure</h2>
            </div>
            <div class="card-body py-1" v-if="departure">
              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="fromLabel" class="col-form-label form-label"
                    >From
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="second_transfer_from"
                      id="fromLabel"
                      v-model="formValues.second_transfer_from"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-6">
                  <label for="toLabel" class="col-form-label form-label"
                    >To
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control transfer-airport"
                      name="second_transfer_to"
                      id="toLabel"
                      v-model="formValues.second_transfer_to"
                      disabled
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="secondTransferDateLabel"
                    class="col-form-label form-label"
                    >Date
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <flat-pickr
                      v-model="formValues.second_transfer_date"
                      class="js-flatpickr form-control flatpickr-custom"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label for="airlineLabel" class="col-form-label form-label"
                    >Airline
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="second_transfer_airline"
                      id="airlineLabel"
                      aria-label="Clarice"
                      v-model="formValues.second_transfer_airline"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="secondTransferFlightLabel"
                    class="col-form-label form-label"
                    >Flight #
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="text"
                      class="form-control"
                      name="second_transfer_flight_no"
                      id="secondTransferFlightLabel"
                      aria-label="Clarice"
                      v-model="formValues.second_transfer_flight_no"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="secondTransferFlightTimeLabel"
                    class="col-form-label form-label"
                    >Flight time
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <input
                      type="time"
                      class="form-control"
                      name="second_transfer_flight_time"
                      id="secondTransferFlightTimeLabel"
                      aria-label="Clarice"
                      v-model="formValues.second_transfer_flight_time"
                      @change="departureFlightTime"
                      required
                    />
                  </div>
                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="secondTransferPickupTimeLabel"
                    class="col-form-label form-label"
                    >Pickup time recommended
                  </label>

                  <div class="d-flex align-items-center gap-2">
                    <div>
                      <input
                        type="radio"
                        class="form-check-input"
                        value="recommended"
                        name="pickup_time"
                        :checked="formValues.pickup_time_recommended != ''"
                        disabled
                      />
                    </div>
                    <div class="input-group input-group-sm-vertical">
                      <input
                        type="time"
                        class="form-control"
                        name="second_transfer_pickup_time_recommended"
                        id="secondTransferPickupTimeLabel"
                        v-model="formValues.pickup_time_recommended"
                        disabled
                      />
                    </div>
                  </div>

                  <span class="text-danger"> </span>
                </div>
                <div class="col-md-3">
                  <label
                    for="secondTransferPickupTimeLabel"
                    class="col-form-label form-label"
                    >Pickup time requested
                  </label>

                  <div class="d-flex align-items-center gap-2">
                    <div>
                      <input
                        type="radio"
                        class="form-check-input"
                        value="requested"
                        name="pickup_time"
                        :checked="formValues.pickup_time_requested != ''"
                      />
                    </div>
                    <div class="input-group input-group-sm-vertical">
                      <input
                        type="time"
                        class="form-control"
                        name="second_transfer_pickup_time_requested"
                        id="secondTransferPickupTimeLabel"
                        v-model="formValues.pickup_time_requested"
                      />
                    </div>
                  </div>

                  <span class="text-danger"> </span>
                </div>
              </div>
              <!-- End Form -->
            </div>
            <div class="card-header">
              <h2>Special Request</h2>
            </div>
            <div class="card-body py-1">
              <div class="row mb-2">
                <div class="col-md-12">
                  <label class="col-form-label form-label"
                    >Other request
                  </label>
                  <div class="input-group input-group-sm-vertical">
                    <textarea
                      class="form-control w-100"
                      name="other_request"
                      v-model="formValues.other_requests"
                    ></textarea>
                  </div>
                  <span class="text-danger"> </span>
                </div>
              </div>
              <!-- End Form -->
            </div>
            <!-- Footer -->
            <div
              class="card-footer d-flex justify-content-end align-items-center gap-3"
            >
              <CustomButton
                buttonType="button"
                classStyle="btn-soft-secondary"
                btnText="Go Back"
                @clickHandler="goBack()"
              />
              <CustomButton
                buttonType="button"
                classStyle="btn-soft-danger"
                btnText="Cancel reservation"
                @clickHandler="cancelBooking()"
                :loading="cancelLoading"
                v-if="status == 'active'"
              />
              <CustomButton
                buttonType="submit"
                classStyle="btn-soft-primary"
                btnText="Update"
                :loading="loading"
                v-if="status == 'active'"
              />
            </div>
          </div>
          <!-- End Footer -->
        </div>
      </div>
    </div>
    <!-- End Row -->
  </form>
  <h4 class="no-reservation-text" v-else>No active reservations found</h4>

  <div class="modal-backdrop fade show" v-if="showCancelModal"></div>
  <div
    :class="`modal-dialog ${showCancelModal ? 'd-block' : ''}`"
    id="cancelModal"
  >
    <div class="modal-content">
      <div class="modal-header px-3 py-1 border-bottom">
        <h3 class="modal-title">Shuttle reservation canceled</h3>
        <button
          type="button"
          class="close btn"
          data-dismiss="modal"
          aria-label="Close"
          @click="continueCancelBooking"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p class="p-3 border-bottom m-0">
        The booking <strong>{{ this.formValues.booking_id }}</strong> has been
        canceled.
      </p>
      <div class="modal-footer p-2">
        <button
          type="button"
          @click="continueCancelBooking"
          class="btn btn-primary"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CustomButton from "../components/CustomButton/index.vue";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";

export default {
  name: "MyBookingView",
  components: {
    CustomButton,
    flatPickr,
  },
  data() {
    return {
      id: null,
      arrival: false,
      departure: false,
      status: "active",
      formValues: {
        booking_id: "",
        name: "",
        email: "",
        phone: "",
        first_transfer_from: "",
        first_transfer_to: "",
        first_transfer_date: "",
        first_transfer_airline: "",
        first_transfer_flight_no: "",
        first_transfer_flight_time: "",
        second_transfer_from: "",
        second_transfer_to: "",
        second_transfer_date: "",
        second_transfer_airline: "",
        second_transfer_flight_no: "",
        second_transfer_flight_time: "",
        pickup_time_requested: "",
        pickup_time_recommended: "",
        other_requests: "",
      },
      loading: false,
      cancelLoading: false,
      promptRequestedTime: false,
      showCancelModal: false,
      morning_message: "Good Morning",
    };
  },
  mounted() {
    const hours = new Date().getHours();
    if (hours > 12 && hours <= 17) {
      this.morning_message = "Good afternoon";
    } else if (hours > 17) {
      this.morning_message = "Good evening";
    }

    if (!this.booking) {
      return;
    }
    if (this.booking.status != "active") {
      return;
    }
    this.id = this.booking.id;
    this.status = this.booking.status;
    this.formValues.booking_id = this.booking.booking_id;
    this.formValues.name = this.booking.name;
    this.formValues.email = this.booking.email;
    this.formValues.phone = this.booking.phone;
    if (this.booking.first_transfer && this.booking.first_transfer.date) {
      this.formValues.first_transfer_from = this.booking.first_transfer.from;
      this.formValues.first_transfer_to = this.booking.first_transfer.to;
      this.formValues.first_transfer_date = this.booking.first_transfer.date;
      this.formValues.first_transfer_airline =
        this.booking.first_transfer.airline;
      this.formValues.first_transfer_flight_no =
        this.booking.first_transfer.flight_no;
      this.formValues.first_transfer_flight_time = this.booking.first_transfer
        .flight_time
        ? this.$moment(this.booking.first_transfer.flight_time, [
            "hh:mm A",
          ]).format("HH:mm")
        : null;
      this.arrival = true;
    }

    if (this.booking.second_transfer && this.booking.second_transfer.date) {
      this.formValues.second_transfer_from = this.booking.second_transfer.from;
      this.formValues.second_transfer_to = this.booking.second_transfer.to;
      this.formValues.second_transfer_date = this.booking.second_transfer.date;
      this.formValues.second_transfer_airline =
        this.booking.second_transfer.airline;
      this.formValues.second_transfer_flight_no =
        this.booking.second_transfer.flight_no;
      this.formValues.second_transfer_flight_time = this.booking.second_transfer
        .flight_time
        ? this.$moment(this.booking.second_transfer.flight_time, [
            "hh:mm A",
          ]).format("HH:mm")
        : null;

      if (this.booking.second_transfer.time_type == "requested") {
        this.formValues.pickup_time_requested = this.booking.second_transfer
          .time
          ? this.$moment(this.booking.second_transfer.time, ["hh:mm A"]).format(
              "HH:mm"
            )
          : null;
      } else if (this.booking.second_transfer.time_type == "recommended") {
        this.formValues.pickup_time_recommended = this.booking.second_transfer
          .time
          ? this.$moment(this.booking.second_transfer.time, ["hh:mm A"]).format(
              "HH:mm"
            )
          : null;
      }

      this.departure = true;
    }
    if (this.booking?.payment?.other_requests) {
      this.formValues.other_requests = this.booking.payment.other_requests;
    }
  },
  computed: {
    ...mapState({
      booking: (state) => state.booking,
      auth_type: (state) => state.auth_type,
    }),
  },
  methods: {
    goBack() {
      this.$store.commit("setBooking", null);
      this.$router.push({
        path: this.auth_type == "bookingId" ? "/" : "/dashboard/bookings",
      });
    },
    submitHandler(e) {
      e.preventDefault();
      if (
        this.promptRequestedTime &&
        this.formValues.pickup_time_requested == ""
      ) {
        this.$toast.open({
          message: "Please fill out the pickup time requested",
          type: "error",
        });
        return;
      }
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}update-user-booking/${this.id}`,
          this.formValues
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.$toast.open({
              message: "Booking Updated.",
              type: "success",
            });
            if (this.formValues.pickup_time_requested != "") {
              this.formValues.pickup_time_recommended = "";
            }
          } else {
            this.$toast.open({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelBooking() {
      this.$swal({
        title: "Would you like to verify that?",
        text: "Don't cancel the reservation",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "I want to cancel",
        cancelButtonText: "Don't cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelHandler();
        }
      });
    },
    cancelHandler() {
      this.cancelLoading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}cancel-user-booking`, {
          id: this.id,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.status = "cancelled";
            this.showCancelModal = true;
            this.$store.commit("setBooking", {
              ...this.booking,
              status: "cancelled",
            });
          } else {
            this.$toast.open({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.cancelLoading = false;
        });
    },
    departureFlightTime() {
      if (
        this.formValues.pickup_time_recommended &&
        this.formValues.pickup_time_recommended != ""
      ) {
        this.promptRequestedTime = true;
        this.formValues.pickup_time_recommended = "";
      }
    },
    continueCancelBooking() {
      this.showCancelModal = false;
      this.id = null;
    },
  },
};
</script>
<style>
#cancelModal {
  position: fixed;
  top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white;
  z-index: 1022;
  border-radius: 0.5rem;
  width: 30rem;
  display: none;
  left: 50%;
  transform: translate(-50%, 0);
}

#cancelModal p {
  color: #1e2022 !important;
}

#cancelModal .close span {
  font-size: 1.5rem;
}

.no-reservation-text{
  padding-top: 6rem;
  font-weight: 400;
  padding-left: 2rem;
}

.heading{
  text-align:center;
  font-size: 1rem;
}
</style>
