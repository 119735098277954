<template>
  <main id="content" role="main" class="main pt-0">
    <!-- Content -->
    <div class="container-fluid px-3">
      <div class="row">
        <div
          class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
        >
          <img
            class="auth-cover"
            src="/assets/image/my-trip.jpg"
            alt="Image Description"
          />
        </div>
        <!-- End Col -->

        <div
          class="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100"
        >
          <RouterView/>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>
  </main>
  <!-- End Content -->
</template>
<script>
export default {
  name: "AuthLayout",
  mounted(){
    if(localStorage.getItem('data')){
        this.$router.push({path: '/dashboard/bookings'});
    }
  }
};
</script>
<style>
.auth-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  min-width: 18rem;
  max-width: 18rem;
  margin-bottom: 1rem;
}

.auth-tab {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.auth-tab .tab-block {
  width: 50%;
  text-align: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
}

.auth-tab .tab-block.active {
  background: #377dff;
  color: white;
}

.social-btn{
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: #1e2022;
  font-weight: 500;
  border: 1px solid rgb(212,212,212);
}

.nsm7Bb-HzV7m-LgbsSe{
  font-weight: 500 !important;
  border: 1px solid rgb(212,212,212) !important;
  padding: 0.75rem !important;
}

.social-btn:hover,
.nsm7Bb-HzV7m-LgbsSe:hover{
  background: #F5F5F5 !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId{
  font-family: 'Inter',sans-serif !important;
}

.g-btn-wrapper{
  width: 100%;
}

.social-btn img{
  width: 20px;
}

.social-btn div{
  width: 100%;
  text-align: center;
}
</style>
