<template>
  <div class="w-100 mt-3" style="max-width: 25rem">
    <!-- Form -->
    <form
      action="#"
      method="POST"
      class="js-validate needs-validation"
      @submit="formHandler"
    >
      <div class="text-center">
        <div class="mb-2 border-bottom pb-1">
          <img
            class="logo"
            src="/assets/image/logo.png"
            alt="Image Description"
          />
          <p class="mb-0">Edit and manage your shuttle booking</p>
        </div>
      </div>
      <p class="heading">Create account</p>
      <div class="mb-2">
        <label class="form-label" for="name">Name</label>
        <input
          type="text"
          class="form-control form-control-lg"
          name="name"
          v-model="formValues.name"
          id="name"
          tabindex="1"
          placeholder="Name"
          required
        />
      </div>
      <div class="mb-2">
        <label class="form-label" for="email">Email</label>
        <input
          type="email"
          class="form-control form-control-lg"
          name="email"
          v-model="formValues.email"
          id="email"
          tabindex="1"
          placeholder="email@gmail.com"
          required
        />
      </div>
      <div class="mb-2">
        <label class="form-label" for="password">Password</label>
        <input
          type="password"
          class="form-control form-control-lg"
          name="password"
          v-model="formValues.password"
          id="password"
          tabindex="1"
          placeholder="*********"
          required
        />
      </div>
      <div class="mb-2">
        <label class="form-label" for="confirm_password"
          >Confirm password</label
        >
        <input
          type="password"
          class="form-control form-control-lg"
          name="confirm_password"
          v-model="formValues.password_confirmation"
          id="confirm_password"
          tabindex="1"
          placeholder="*********"
          required
        />
      </div>
      <!-- End Form -->

      <div class="d-grid border-bottom pb-4">
        <CustomButton
          buttonType="submit"
          classStyle="btn-primary btn-lg"
          btnText="Register"
          :loading="loading"
        />
      </div>
      <p class="my-2 text-center">Or</p>
      <GoogleLogin
        :callback="callback"
        prompt
      >
        <div class="social-btn rounded">
          <img src="/assets/image/google.png" />
          <div>Sign up with Google</div>
        </div>
      </GoogleLogin>
      <p class="text-end mt-2">
        Already have an account?
        <router-link to="/">Login</router-link>
      </p>
    </form>
    <!-- End Form -->
  </div>
</template>
<script>
import CustomButton from "../components/CustomButton/index.vue";
import axios from "axios";
import { decodeCredential } from "vue3-google-login";

export default {
  name: "AuthView",
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
      formValues: {
        email: null,
        name: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    toggleTab(tab) {
      this.tab = tab;
    },
    formHandler(e) {
      e.preventDefault();
      if (this.formValues.password != this.formValues.password_confirmation) {
        this.$toast.open({
          message: "Passwords not matched",
          type: "error",
        });
        return;
      }
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}website/register`, this.formValues)
        .then((response) => {
          if (response.data.status == 200) {
            this.$router.push({ path: "/" });
            this.$toast.open({
              message: "Registered successfully",
              type: "success",
            });
          } else {
            this.$toast.open({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    callback(response) {
      // decodeCredential will retrive the JWT payload from the credential
      const userData = decodeCredential(response.credential);
      console.log("Handle the userData", userData);
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}website/google-login`, {
          google_id: userData.sub,
          email: userData.email,
          name: userData.name,
        })
        .then((res) => {
          if (res.data.status == 200) {
            localStorage.setItem("data", JSON.stringify(res.data.data));
            this.$store.commit("setAuthType", "password");
            this.$router.push({ path: "/dashboard/bookings" });
          } else {
            this.$toast.open({
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
