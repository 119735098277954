<template>
  <div class="content container-fluid">
    <div class="page-header">
      <!-- End Page Header -->
      <div
        class="d-sm-flex justify-content-lg-between align-items-sm-center mb-3"
      >
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-no-gutter">
              <li class="breadcrumb-item">
                <a class="breadcrumb-link" href="javascript:;">Bookings</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Overview
              </li>
            </ol>
          </nav>

          <h1 class="page-header-title">Bookings</h1>
        </div>
      </div>

      <!-- Card -->
      <div class="card">
        <!-- Table -->
        <div class="table-responsive datatable-custom position-relative">
          <table
            class="table table-lg table-borderless table-hover table-thead-bordered table-nowrap table-align-middle card-table booking-table entry-table"
          >
            <thead class="thead-light">
              <tr>
                <th>Booking ID</th>
                <th>Name</th>
                <th>Vehicle</th>
                <th>Trip</th>
                <th>Date (1st)</th>
                <th>Date (2nd)</th>
                <th>Pending (Total)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(each, index) in bookings"
                :key="index"
                class="cursor-pointer"
                @click="editBooking(each)"
              >
                <td>
                  <div class="d-flex align-items-center gap-2">
                    <div>
                      {{ each.booking_id }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center gap-2">
                    <div>{{ each.name }}</div>
                  </div>
                </td>
                <td>
                  {{ each.vehicle }}
                </td>
                <td>
                  {{ each.trip }}
                </td>
                <td>
                  {{ each.first_transfer ? $moment(each.first_transfer.date).format('MM/DD/YYYY') : 'N/A'}}
                </td>
                <td>
                  {{ each.second_transfer ? $moment(each.second_transfer.date).format('MM/DD/YYYY') : 'N/A'}}
                </td>
                <td>
                  ${{ (each.request && each.request.pending) ? each.request.pending : '0'}}
                </td>
                <td>
                  <div v-if="each.status == 'cancelled' || each.status == 'user-cancelled'" class="badge bg-soft-danger text-danger p-2">Cancelled</div>
                  <div v-else-if="each.status == 'active'" class="badge bg-soft-primary text-primary p-2">Active</div>
                  <div v-else class="badge bg-soft-warning text-warning p-2">Pending</div>
                </td>
              </tr>
              <tr v-if="bookings.length == 0 && !loading">
                <td class="text-center"  colspan="7">
                No available bookings found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center my-4" v-if="loading">
            <SpinnerComponent/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axiosInstance from "@/utils/axiosInstance";
import SpinnerComponent from '@/components/Spinner/index.vue';

export default {
  name: "BookingView",
  components: {
    SpinnerComponent
  },
  data() {
    return {
      bookings: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      const response = await axiosInstance.get("website/bookings");
      this.loading = false;
      if (response.data.status == 200) {
        this.bookings = response.data.data;
        
      }
    } catch (e) {
      this.loading = false;

      console.log(e);
    }
  },
  computed: {
    ...mapState({
      booking: (state) => state.booking,
    }),
  },
  methods: {
    editBooking(data){
      this.$store.commit('setBooking', data);
      this.$router.push({ path: "/dashboard/my-booking" });
    }
  }
};
</script>
