<template>
  <div class="w-100 mt-3" style="max-width: 25rem">
    <!-- Form -->
    <form class="js-validate needs-validation" @submit="formHandler">
      <div class="text-center">
        <div class="mb-2 border-bottom pb-1">
          <img
            class="logo"
            src="/assets/image/logo.png"
            alt="Image Description"
          />
          <p class="mb-0">Enter the code sent to your email address.</p>
        </div>
      </div>
      <p class="heading">Verify email address.</p>

      <!-- Form -->
      <div class="mb-2">
        <label class="form-label" for="name">Verification code</label>
        <input
          type="text"
          class="form-control form-control-lg"
          name="otp"
          v-model="formValues.otp"
          id="otp"
          tabindex="1"
          placeholder="Enter the four digit number"
          required
        />
      </div>
      <div class="mb-2">
        <label class="form-label" for="password">Password</label>
        <input
          type="password"
          class="form-control form-control-lg"
          name="password"
          v-model="formValues.password"
          id="password"
          tabindex="1"
          placeholder="*********"
          required
        />
      </div>
      <div class="mb-2">
        <label class="form-label" for="confirm_password"
          >Confirm password</label
        >
        <input
          type="password"
          class="form-control form-control-lg"
          name="confirm_password"
          v-model="formValues.password_confirmation"
          id="confirm_password"
          tabindex="1"
          placeholder="*********"
          required
        />
      </div>
      <!-- End Form -->

      <div class="d-grid gap-2">
        <CustomButton
          buttonType="submit"
          classStyle="btn-primary btn-lg"
          btnText="Submit"
          :loading="loading"
        />

        <div class="text-center">
          <router-link class="btn btn-link font-light" to="/">
            <i class="bi-chevron-left"></i> Back to Sign in
          </router-link>
        </div>
      </div>
    </form>
    <!-- End Form -->
  </div>
</template>
<script>
import CustomButton from "../components/CustomButton/index.vue";
import axios from "axios";

export default {
  name: "AuthView",
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
      formValues: {
        email: null,
        otp: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  mounted() {
    if (!this.$route.query.email) {
      this.$router.push({ path: "/" });
      return;
    }

    this.formValues.email = this.$route.query.email;
  },
  methods: {
    formHandler(e) {
      e.preventDefault();
      if (this.formValues.password != this.formValues.password_confirmation) {
        this.$toast.open({
          message: "Passwords not matched",
          type: "error",
        });
        return;
      }
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}website/verify-forgot-password-otp`,
          this.formValues
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.$router.push({ path: "/" });
            this.$toast.open({
              message: "Password reset successfully",
              type: "success",
            });
          } else {
            this.$toast.open({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
