<template>
  <div class="content-area">
    <div class="article-content-page">
      <div class="feedback-image">
        <div class="w-lg-80 text-center mx-auto image-container">
          <h1 class="main-title-text">Got a question?</h1>
          <p class="text-white lead" style="text-align: center">
            We’d love to talk about how we can help you.
          </p>
        </div>
      </div>
      <div class="clearfix space-2">
        <div class="row no-gutters">
          <div class="col-md-4 contact-information">
            <div class="text-center py-5">
              <img src="/assets/image/phone-icon.svg" />
              <h2>Phone Number</h2>
              <p>+1 (877) 899-1121</p>
            </div>
          </div>
          <div class="col-md-4 contact-information divider">
            <div class="text-center py-5">
              <img src="/assets/image/address-icon.svg" />
              <h2>Address</h2>
              <p>
                Blvd. Constituyentes 1723-Local 12, El Arenal, 23460, Los Cabos
              </p>
            </div>
          </div>
          <div class="col-md-4 contact-information">
            <div class="text-center py-5">
              <img src="/assets/image/email-icon.svg" />
              <h2>Email</h2>
              <p>sales@caboairportshuttle.net</p>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-0" />
      <div class="contact-form">
        <div class="text-block">
          <h2 class="text-primary">How we can help?</h2>
          <p>
            Customer service is open from 9 a.m. to 5 p.m. MST, Monday through
            Sunday.
          </p>
        </div>
        <form @submit="submitHandler">
          <div class="row">
            <div class="col-md-4 mb-6">
              <div class="js-form-message">
                <label class="form-label">
                  Your name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="formValues.name"
                  placeholder="Enter your name."
                  required
                />
              </div>
            </div>

            <div class="col-md-4 mb-6">
              <div class="js-form-message">
                <label class="form-label">
                  Your email address
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="Enter a valid email address"
                  aria-label="jackwayley@gmail.com"
                  required
                  v-model="formValues.email"
                />
              </div>
            </div>

            <div class="col-md-4 mb-6">
              <div class="js-form-message">
                <label class="form-label">
                  Your Phone Number
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="phone"
                  placeholder="+1-800-643-4500"
                  v-model="formValues.phone"
                  required
                />
              </div>
            </div>
          </div>

          <div class="js-form-message mb-6">
            <label class="form-label">
              How can we help you?
              <span class="text-danger">*</span>
            </label>
            <div class="input-group">
              <textarea
                class="form-control"
                rows="4"
                name="description"
                placeholder="Hi there, I would like to ..."
                aria-label="Hi there, I would like to ..."
                required
                v-model="formValues.description"
              ></textarea>
            </div>
          </div>

          <div class="text-center">
            <CustomButton
              buttonType="submit"
              classStyle="btn-primary"
              btnText="Submit"
              :loading="loading"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "../components/CustomButton/index.vue";
import axios from "axios";

export default {
  name: "CustomerFeedbackView",
  components: {
    CustomButton,
  },
  data() {
    return {
      loading: false,
      formValues: {
        email: "",
        phone: "",
        name: "",
        description: "",
      },
    };
  },
  methods: {
    submitHandler(e) {
      e.preventDefault();
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}submit-customer-feedback`,
          this.formValues
        )
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: response.data.status == 200 ? "success" : "error",
          });
          if (response.data.status == 200) {
            this.formValues = {
              email: "",
              phone: "",
              name: "",
              description: "",
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Something went wrong!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.content-area {
  margin: 0 1rem;
}

.main-title-text {
  font-size: 2.5rem;
  color: white;
}

.feedback-image {
  height: 20rem;
  background-color: #1e2022 !important;
}

.feedback-image .image-container {
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(126, 127, 128, 0.52),
      rgba(107, 107, 107, 0.73)
    ),
    url("../assets/customer-support.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-information {
  margin: 3rem 0;
}

.contact-information.divider {
  border-right: 1px solid #e7eaf3;
  border-left: 1px solid #e7eaf3;
}

.contact-information img {
  max-width: 4rem;
  margin: 1rem auto;
}

.contact-information h2 {
  font-size: 1rem;
  font-weight: 600;
}

.contact-information p {
  color: #77838f;
  font-size: 1rem;
}

.contact-form {
  margin: 4rem 0;
}

.contact-form .text-block {
  text-align: center;
}

.contact-form .text-block h2 {
  font-size: 2rem;
  font-weight: 400;
}

.contact-form .text-block h2 span {
  font-weight: 600;
}

.contact-form .text-block p {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #77838f;
  font-size: 1rem;
}

.contact-form form {
  width: 80%;
  margin: 2rem auto;
}

@media screen and (max-width: 600px) {
  .contact-information.divider {
    border: none !important;
  }

  .contact-form form {
    width: 100%;
  }
}
</style>
